import { CLEAR_ADD_ARTIST, CLEAR_UPDATE_ARTIST } from '../../actionTypes';

export default (type) => async (dispatch) => {
  if (type === 'signup') {
    dispatch({
      type: CLEAR_ADD_ARTIST,
    });
  }
  if (type === 'temp-update') {
    dispatch({
      type: CLEAR_UPDATE_ARTIST,
    });
  }
};
