/* eslint-disable radix */

export const monthToLetter = (month, year) => {
  const months = [
    null, 'Jan', 'Feb', 'March', 'April', 'May', 'June',
    'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
  ];
  return [months[month], year].join(' ');
};

export const findMonth = (month, year) => (item) => item === monthToLetter(
  Number(month), Number(year)
);

export default (startDate, endDate) => {
  const start = startDate.split('-');
  const end = endDate.split('-');
  const startYear = parseInt(start[0]);
  const endYear = parseInt(end[0]);
  const dates = [];

  for (let i = startYear; i <= endYear; i += 1) {
    const endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
    const startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      dates.push(monthToLetter(j + 1, i));
    }
  }
  return dates;
};
