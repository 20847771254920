import { useState, useEffect } from 'react';
import fetchProfile from './redux/actions/user/fetchProfile';

const useProfile = () => {
  const [status, setStatus] = useState('fetching');
  const [profile, setProfile] = useState({});

  useEffect(() => {
    const initiate = async () => {
      try {
        if (localStorage.getItem('INGOMA_PROVIDER_TOKEN')) {
          const profileData = await fetchProfile();
          setProfile(profileData);
          localStorage.setItem(
            'INGOMA_PROVIDER_SIDEBAR_DATA',
            JSON.stringify(profileData)
          );
          setStatus('success');
        }
      } catch (error) {
        setStatus('error');
      }
    };
    initiate();
  }, []);

  return [status, profile];
};

export default useProfile;
