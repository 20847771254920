import user from './user';
import resetPassword from './resetPassword';
import changePassword from './changePassword';
import newPassword from './newPassword';
import upsert from './upsertPayment';
import updateProvider from './updateProvider';

export default {
  user,
  resetPassword,
  newPassword,
  changePassword,
  upsert,
  updateProvider,
};
