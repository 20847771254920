import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import validator from '../helpers/validator';
import resetAction from '../redux/actions/user/resetPassword';

const ForgotPassword = ({ resetAction: resetPassword, resetData }) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [emailReset, setEmailReset] = useState(false);
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('INGOMA_PROVIDER_TOKEN')) return history.push('/');
    setSubmitting(false);
    if (resetData.status === 'error') {
      return setErrors([resetData.error.message]);
    }
    if (resetData.status === 'success') {
      setEmailReset(true);
      setEmail('');
    }
    return undefined;
  }, [resetData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { email };
    const validationErrors = await validator(data);
    if (validationErrors.length > 0) return setErrors(validationErrors);
    setErrors([]);
    setSubmitting(true);
    return resetPassword(data);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row sign-container justify-content-end">
          <div
            className="col-lg-4 py-5 text-white"
            style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
          >
            <div className="row">
              <div className="col">
                <div className="text-center">
                  <h3>Ingoma</h3>
                  <p>Forgot Password</p>
                </div>
                <p className="text-center">
                  Please Enter Your Email Address, We Will Send A Password Reset
                  Link.
                </p>
                <form className="px-5 pt-4">
                  {errors.map((error, index) => (
                    <div
                      className="alert alert-danger"
                      role="alert"
                      key={index}
                    >
                      {error}
                      <button
                        type="button"
                        className="close"
                        onClick={() => setErrors([])}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ))}
                  {emailReset ? (
                    <div
                      className="alert alert-warning text-center medium-text"
                      role="alert"
                    >
                      A password reset link was successfully sent, Please check
                      your email.
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                  <div className="form-group py-1">
                    <div className="form-group">
                      <input
                        type="email"
                        className={`form-control form-control-lg authInput ${
                          submitting ? 'cursor-not-allowed' : ''
                        }`}
                        placeholder="Email"
                        required="required"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        disabled={!!submitting}
                      />
                    </div>
                  </div>
                  <div className="px-5 text-center">
                    {submitting ? (
                      <button
                        type="submit"
                        className="btn btn-secondary btn-block login-button"
                        disabled
                      >
                        RESETTING
                        <span
                          className="spinner-border spinner-border ml-2"
                          style={{
                            width: '1.5rem',
                            height: '1.5rem',
                            fontSize: '0.7rem',
                          }}
                          role="status"
                          aria-hidden="true"
                        />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        onClick={handleSubmit}
                      >
                        RESET
                      </button>
                    )}
                  </div>
                  <p className="text-center py-3">
                    <Link className="text-primary" to="/login">
                      Back To Log In
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ForgotPassword.propTypes = {
  resetAction: PropTypes.func.isRequired,
  resetData: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ resetPassword }) => ({ resetData: resetPassword });

export default connect(mapStateToProps, { resetAction })(ForgotPassword);
