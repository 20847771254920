/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ progress }) => {
  return (
    <div
      className="progress"
      style={{ height: 15, backgroundColor: '#585858' }}
    >
      <div
        className="progress-bar font-weight-bold py-1"
        style={{ width: `${progress}%` || 0, height: 15, color: '#000' }}
      >
        {`${progress}%`}
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default ProgressBar;
