/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import artistPlaceholder from '../../assets/images/placeHolder.png';

const Table = ({ data = [] }) => {
  const [loading, setLoading] = useState(true);
  return (
    <>
      <div className="border rounded">
        <table className="table text-white text-center mb-0">
          <thead>
            <tr>
              <th scope="col">Picture</th>
              <th scope="col">Artist Name</th>
              <th scope="col">Monthly Streams</th>
              <th scope="col">Total Streams</th>
              <th scope="col">Status</th>
              <th scope="col">Edit</th>
              <th scope="col">Details</th>
            </tr>
          </thead>
          <tbody>
            {data.map((artist) => (
              <tr key={artist._id}>
                <th label="image" scope="row">
                  <img
                    className="rounded-circle"
                    width="40px"
                    height="40px"
                    style={{ objectFit: 'cover' }}
                    src={
                      artist.profilePhotoLink
                        ? loading
                          ? artistPlaceholder
                          : artist.profilePhotoLink
                        : artist.profilePhotoLink
                    }
                    alt="Artist_Image"
                    srcSet=""
                    onLoad={() => setLoading(false)}
                  />
                </th>
                <td>{artist.name}</td>
                <td>{artist.monthlyStreams.toLocaleString()}</td>
                <td>{artist.totalStreams.toLocaleString()}</td>
                <td>
                  {artist.status.charAt(0).toUpperCase() +
                    artist.status.slice(1)}
                </td>
                <td>
                  <Link
                    to={`/artists/${artist._id}/application/update`}
                    className={`btn btn-primary px-4 py-1 ${
                      artist.status === 'verified' ? 'disable-btn' : ''
                    }`}
                    title="Update profile for artist with pending or denied status"
                  >
                    Edit
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/artists/${artist._id}`}
                    className={`btn btn-dark px-4 py-1 ${
                      artist.status !== 'verified' ? 'disable-btn' : ''
                    }`}
                  >
                    Details
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    )
  ).isRequired,
};

export default Table;
