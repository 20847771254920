import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import withSidebar from '../withSidebar';
import NumberCard from '../components/NumberCard';
import StreamsChart from '../components/Charts/SetStreams';
import Loader from '../components/Loader';
import Error from '../components/Error';
import AddTrack from '../components/AddTrack';
import UpdateSet from '../components/UpdateSet';
import musicPlaceholder from '../assets/images/music.png';
import fetchSetAction from '../redux/actions/sets/singleSet';
import releaseSetAction from '../redux/actions/sets/releaseSet';
import clearReleaseAction from '../redux/actions/sets/clearRelease';
import ConfirmAction from '../components/ConfirmAction';

const Set = ({
  fetchSetAction: fetchSet,
  singleSet,
  releaseSetAction: releaseSet,
  release,
  clearReleaseAction: clearRelease,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const [set, setSet] = useState({});
  const [tracks, setTracks] = useState([]);
  const [imageLoading, setImageLoading] = useState(true);
  const [status, setStatus] = useState('initial');
  const [releaseStatus, setReleaseStatus] = useState('');

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_PROVIDER_TOKEN')) {
      return history.push('/login');
    }
    if (status === 'initial') {
      fetchSet(id);
      setReleaseStatus('');
      clearRelease();
      setStatus('fetching');
    }

    if (singleSet.status === 'clear') {
      setStatus('fetching');
      setReleaseStatus('');
      setSet({});
      setTracks([]);
    }

    if (singleSet.status === 'success') {
      setStatus('success');
      const { set: retrievedSet } = singleSet.results;
      setSet(retrievedSet);
      setTracks(retrievedSet.tracks);
    }

    if (singleSet.status === 'error') {
      const { error } = singleSet;
      if (error.status === 500) setStatus('unknown_error');
      if (error.status === 404) setStatus('no_data');
    }

    if (release.status) {
      setReleaseStatus(release.status);
    }

    return undefined;
  }, [singleSet, release]);

  const handleRelease = () => {
    releaseSet(id);
    setReleaseStatus('submitting');
  };

  const refetch = () => fetchSet(id);

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'no_data':
        data = (
          <Error title="No data found!" description="The set does not exist!" />
        );
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  const ReleaseAlert = () => {
    let alert;
    switch (releaseStatus) {
      case 'success':
        alert = (
          <div className="alert alert-top-right alert-warning" role="alert">
            Released Successfully!
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        );
        break;
      case 'error':
        alert = (
          <div className="alert alert-top-right alert-danger" role="alert">
            Ooops, Something went wrong. Release Failed!
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        );
        break;
      default:
        alert = null;
    }
    return <div>{alert}</div>;
  };

  const live = set.isLive || releaseStatus === 'success';

  return (
    <>
      <ReleaseAlert />
      <div className="row mt-2">
        <NumberCard
          col={4}
          count={singleSet.results.musicSetStreams}
          body="Streams This Month"
        />
        <NumberCard
          col={4}
          count={singleSet.results.musicSetAllTimeStreams}
          body="Total Streams"
        />
      </div>
      <div className="py-3">
        <div className="row">
          <DisplayData>
            <div className="col-3 text-center text-white">
              <img
                className="img-fluid rounded my-3"
                src={imageLoading ? musicPlaceholder : set.picture}
                style={{ height: '300px', width: '300px', objectFit: 'cover' }}
                alt=""
                srcSet=""
                onLoad={() => setImageLoading(false)}
              />
              <h4 className="my-0">{set.name}</h4>
              <p>{set.type}</p>
              <button
                type="button"
                className="btn btn-primary px-3 py-1 my-2"
                data-toggle="modal"
                data-target="#updateSetModal"
                disabled={live}
              >
                Update
              </button>
              <UpdateSet refetch={refetch} artist={id} setData={set} />
            </div>
          </DisplayData>
          <div className="col-9">
            <StreamsChart id={id} />
          </div>
        </div>
      </div>
      <DisplayData>
        <div className="py-3 text-white">
          <div className="row">
            <div className="col text-left my-2">
              <h4>Tracks</h4>
            </div>
            <div className="col text-right">
              <>
                <button
                  type="button"
                  className="btn btn-primary px-3 py-1 my-2"
                  data-toggle="modal"
                  data-target="#addTrackModal"
                  disabled={live}
                >
                  + Add Track
                </button>
                <AddTrack
                  set={id}
                  artist={set.artist ? set.artist._id : ''}
                  refetch={refetch}
                />
                {releaseStatus === 'submitting' ? (
                  <button
                    type="button"
                    className="btn btn-secondary px-5 py-1 my-2 ml-2"
                    disabled
                  >
                    Releasing
                    <span
                      className="spinner-border spinner-border ml-2"
                      style={{
                        width: '1.2rem',
                        height: '1.2rem',
                        fontSize: '0.5rem',
                      }}
                      role="status"
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-secondary px-5 py-1 my-2 ml-2"
                    disabled={live || !tracks.length}
                    data-toggle="modal"
                    data-target="#confirm"
                  >
                    {live ? 'Released' : 'Release'}
                  </button>
                )}
              </>
            </div>
          </div>
          <div className="mt-3 mx-5">
            {tracks.length ? (
              tracks.map((track, index) => (
                <div
                  className="row border-bottom border-primary border-2 py-3"
                  key={track._id}
                >
                  <div className="col-1 text-center">{index + 1}</div>
                  <div className="col-4">{track.title}</div>
                  <div className="col-4">{track.artists}</div>
                  <div className="col-3 text-center">
                    {`${track.allTimeStreams.toLocaleString()} Total, `}
                    {`${track.monthlyStreams.toLocaleString()} This Month`}
                  </div>
                </div>
              ))
            ) : (
              <Error
                title="No data found!"
                description="No tracks added yet!"
              />
            )}
          </div>
        </div>
      </DisplayData>
      <ConfirmAction action={handleRelease} />
    </>
  );
};

Set.propTypes = {
  fetchSetAction: PropTypes.func.isRequired,
  singleSet: PropTypes.objectOf(PropTypes.any).isRequired,
  releaseSetAction: PropTypes.func.isRequired,
  clearReleaseAction: PropTypes.func.isRequired,
  release: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ singleSet, release }) => ({ singleSet, release });

export default connect(mapStateToProps, {
  fetchSetAction,
  releaseSetAction,
  clearReleaseAction,
})(withSidebar(Set, 'music'));
