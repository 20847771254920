import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import phoneSuccess from '../../assets/images/phoneSuccess.png';
import withSidebar from '../../withSidebar';
import vector1 from '../../assets/images/vector1.png';
import SignUpNav from '../../components/forms/SignUpNav';
import clearSuccessPageAction from '../../redux/actions/artists/clearSuccessPage';

const SignUpSuccess = ({ clearSuccessPageAction: clearAction }) => {
  useEffect(() => {
    clearAction('signup');
    return undefined;
  });

  return (
    <>
      <h5
        className="text-white text-center mt-4"
        style={{ fontSize: 18, width: '90%', margin: 'auto' }}
      >
        Add New Artist
      </h5>
      <SignUpNav step={4} />
      <div className="register-content mx-auto mt-3 pb-3">
        <div className="row">
          <div className="col-6 div-1">
            <div className="auth-form auth-success">
              <h3 className="text-white font-weight-bold">
                Artist added Successfully
              </h3>
              <p className="text-white" style={{ marginTop: 30 }}>
                New artist was added successfully! We are currently reviewing
                the submitted info and we’ll reach out ASAP.
              </p>

              <Link className="btn btn-primary py-1 mt-4 px-4" to="/artists">
                Back to Artists
              </Link>
            </div>
          </div>
          <div className="col-6 div-2">
            <div className="form-cover-img-div">
              <img src={vector1} alt="vector-pic" className="vector-1-image" />
              <div className="sm-form-cover-div">
                <img
                  src={phoneSuccess}
                  alt="phone-success-pic"
                  className="phone-success-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SignUpSuccess.propTypes = {
  clearSuccessPageAction: PropTypes.func.isRequired,
};
export default connect(null, {
  clearSuccessPageAction,
})(withSidebar(SignUpSuccess, 'music'));
