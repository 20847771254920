import singleSet from './singleSet';
import addSet from './addSet';
import release from './releaseSet';
import updateSet from './updateSet';

export default {
  singleSet,
  addSet,
  release,
  updateSet,
};
