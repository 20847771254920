import {
  UPSERT_PAYMENT_SUCCESS,
  UPSERT_PAYMENT_FAILED,
} from '../../actionTypes';

const initialState = {
  status: '',
  message: '',
  results: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPSERT_PAYMENT_SUCCESS:
      return {
        ...state,
        status: 'upsert_success',
        message: action.message,
        results: action.data,
      };
    case UPSERT_PAYMENT_FAILED:
      return { ...state, status: 'upsert_error', error: action.error };
    default:
      return state;
  }
};
