import { ADD_TRACK_SUCCESS, ADD_TRACK_FAILED, CLEAR_ADD_TRACK } from '../../actionTypes';

const initialState = {
  status: '',
  message: '',
  results: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TRACK_SUCCESS:
      return {
        ...state,
        status: 'success',
        message: action.message,
        results: action.results,
      };
    case ADD_TRACK_FAILED:
      return { ...state, status: 'error', error: action.error };
    case CLEAR_ADD_TRACK:
      return initialState;
    default:
      return state;
  }
};
