import React from 'react';
import PropTypes from 'prop-types';

const NumberCard = ({ col, count, body }) => (
  <div className={`col-lg-${col}`}>
    <div className="my-2 mx-2 px-3 py-3 text-white bg-dark rounded">
      <h4 className="my-0">
        {typeof count === 'undefined' ? '-' : count.toLocaleString()}
      </h4>
      <p className="my-0">{body}</p>
    </div>
  </div>
);

NumberCard.propTypes = {
  col: PropTypes.number.isRequired,
  count: PropTypes.number,
  body: PropTypes.string.isRequired,
};

NumberCard.defaultProps = {
  count: 0,
};

export default NumberCard;
