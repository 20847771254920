import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';

const NotFound = () => (
  <>
    <div className="loading-container">
      <div className="text-center">
        <img src={logo} width="35%" className="load-img" alt="logo" />
        <div className="col text-center text-white py-5">
          <h4>404 PAGE NOT FOUND!</h4>
          <p className="mt-1 small-text">
            Looks like the page you&#39;re looking for is not available!
          </p>
          <Link className="btn btn-primary py-1 px-4 mt-3" to="/">
            Go To Home
          </Link>
        </div>
      </div>
    </div>
  </>
);

export default NotFound;
