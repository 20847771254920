import { FETCH_ARTISTS_SUCCESS, FETCH_ARTISTS_FAILED, CLEAR_ARTISTS } from '../../actionTypes';

const initialState = {
  status: '',
  message: '',
  results: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTISTS_SUCCESS:
      return {
        ...state,
        status: 'fetch_artists_success',
        message: action.message,
        results: action.results,
      };
    case FETCH_ARTISTS_FAILED:
      return { ...state, status: 'fetch_artists_error', error: action.error };
    case CLEAR_ARTISTS:
      return { ...initialState, status: 'clear' };
    default:
      return state;
  }
};
