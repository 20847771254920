import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ noPadding }) => (
  <div className={`col text-center ${noPadding ? '' : 'py-5'}`}>
    <div
      className="spinner-border text-primary spinner-border"
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

Loader.propTypes = {
  noPadding: PropTypes.bool
};

Loader.defaultProps = {
  noPadding: false
};

export default Loader;
