import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart as BChart,
  LineChart as Lchart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import Loader from '../Loader';
import Error from '../Error';

const ChartContainer = ({ type, status, data = [] }) => {
  const SelectedChart = type === 'bar' ? BChart : Lchart;

  const Chart = () => (
    <ResponsiveContainer width="100%" height={400}>
      <SelectedChart width={730} height={250} data={data}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="period" />
        <YAxis dataKey="total" axisLine={false} tickLine={false} />
        <Tooltip
          cursor={false}
          contentStyle={{
            background: '#232323',
            color: 'white',
            border: 0,
          }}
        />
        {type === 'bar' ? (
          <Bar dataKey="total" fill="#E5DA2F" />
        ) : (
          <Line dataKey="total" stroke="#E5DA2F" strokeWidth={3} />
        )}
      </SelectedChart>
    </ResponsiveContainer>
  );

  const DisplayData = ({ children }) => {
    let details;
    switch (status) {
      case 'success':
        details = <>{children}</>;
        break;
      case 'fetching':
        details = (
          <>
            <Chart />
            <div className="graph-Loader">
              <Loader noPadding />
            </div>
          </>
        );
        break;
      case 'no_data':
        details = (
          <>
            <Chart />
            <div className="graph-Loader">
              <Error
                title="No data found!"
                description="No Streaming Data Found for the period!"
                noPadding
              />
            </div>
          </>
        );
        break;
      default:
        details = (
          <>
            <Chart />
            <div className="graph-Loader">
              <Error
                title="Error!"
                description="Something went wrong, Please try again in a while."
                noPadding
              />
            </div>
          </>
        );
    }
    return details;
  };

  return (
    <DisplayData>
      <Chart />
    </DisplayData>
  );
};

ChartContainer.propTypes = {
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    )
  ).isRequired,
};

export default ChartContainer;
