import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withSidebar from '../withSidebar';
import NumberCard from '../components/NumberCard';
import StreamsChart from '../components/Charts/ArtistStreams';
import MusicCard from '../components/MusicCard';
import AddSet from '../components/AddSet';
import UpdateArtist from '../components/UpdateArtist';
import UpdateCoverPhoto from '../components/UpdateCoverPhoto';
import Loader from '../components/Loader';
import Error from '../components/Error';
import artistPlaceholder from '../assets/images/placeHolder.png';
import fetchArtistAction from '../redux/actions/artists/singleArtist';

const Artist = ({ fetchArtistAction: fetchArtist, singleArtist }) => {
  const history = useHistory();
  const { id } = useParams();
  const [artist, setArtist] = useState({});
  const [imageLoading, setImageLoading] = useState(true);
  const [music, setMusic] = useState([]);
  const [status, setStatus] = useState('initial');

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_PROVIDER_TOKEN')) {
      return history.push('/login');
    }
    if (status === 'initial') {
      fetchArtist(id);
      setStatus('fetching');
    }

    if (singleArtist.status === 'clear') {
      setStatus('fetching');
      setArtist({});
      setMusic([]);
    }

    if (singleArtist.status === 'success') {
      setStatus('success');
      setArtist(singleArtist.results.artist);
      setMusic(singleArtist.results.sets);
    }

    if (singleArtist.status === 'error') {
      const { error } = singleArtist;
      if (error.status === 500) setStatus('unknown_error');
      if (error.status === 404) setStatus('no_data');
    }

    return undefined;
  }, [singleArtist]);

  const refetch = () => fetchArtist(id);

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'no_data':
        data = (
          <Error
            title="No data found!"
            description="The artist does not exist!"
          />
        );
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  return (
    <>
      <div className="row mt-2">
        <NumberCard
          col={4}
          count={singleArtist.results.artistMonthlyStreams}
          body="Streams This Month"
        />
        <NumberCard
          col={4}
          count={singleArtist.results.artistAllTimeStreams}
          body="Total Streams"
        />
      </div>
      <div className="py-3">
        <div className="row">
          <DisplayData>
            <div className="col-3 text-center text-white">
              <img
                className="img-fluid rounded my-3"
                src={imageLoading ? artistPlaceholder : artist.profilePhotoLink}
                style={{ height: '300px', width: '300px', objectFit: 'cover' }}
                alt=""
                srcSet=""
                onLoad={() => setImageLoading(false)}
              />
              <h4 className="my-0">{artist.name}</h4>
              <p>Artist</p>
              <button
                type="button"
                className="btn btn-primary px-3 py-1 my-2"
                data-toggle="modal"
                data-target="#updateArtistModal"
              >
                Update Picture
              </button>
              <UpdateArtist
                refetch={refetch}
                artist={id}
                artistData={artist.profilePhotoLink}
              />
              <button
                type="button"
                className="btn btn-dark px-3 py-1 my-2"
                data-toggle="modal"
                data-target="#UpdateCoverPhotoModal"
              >
                Update Cover Photo
              </button>
              <UpdateCoverPhoto
                refetch={refetch}
                artist={id}
                artistData={artist.coverPhoto}
              />
            </div>
          </DisplayData>
          <div className="col-9">
            <StreamsChart id={id} />
          </div>
        </div>
      </div>
      <DisplayData>
        <div className="py-3 text-white">
          <div className="row">
            <div className="col text-left my-2">
              <h4>Music</h4>
            </div>
            <div className="col text-right">
              <button
                type="button"
                className="btn btn-primary px-3 py-1 my-2"
                data-toggle="modal"
                data-target="#musicModal"
              >
                + Add Music
              </button>
              <AddSet refetch={refetch} artist={id} />
            </div>
          </div>
          <div className="row">
            {music.length ? (
              music.map((set) => (
                <MusicCard
                  key={set.id}
                  music={set}
                  goToSet={() => history.push(`/sets/${set._id}`)}
                />
              ))
            ) : (
              <Error
                title="No data found!"
                description="No music sets added yet!"
              />
            )}
          </div>
        </div>
      </DisplayData>
    </>
  );
};

Artist.propTypes = {
  fetchArtistAction: PropTypes.func.isRequired,
  singleArtist: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ singleArtist }) => ({ singleArtist });

export default connect(mapStateToProps, { fetchArtistAction })(
  withSidebar(Artist, 'music')
);
