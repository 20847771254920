import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

const ConfirmAction = ({ action }) => (
  <div
    className="modal fade"
    id="confirm"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="formModal"
    aria-hidden="true"
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <div className="modal-dialog" role="document" style={{ width: '100%' }}>
        <div
          className="modal-content bg-secondary text-white border-0 artist-modal"
          style={{ margin: 0 }}
        >
          <div className="modal-header">
            <h6 className="modal-title mx-auto text-center" id="formModal">
              Confirm Action
            </h6>
            <span
              data-dismiss="modal"
              className="close-button cursor-pointer text-center"
            >
              &times;
            </span>
          </div>
          <div className="px-3 pt-0 modal-body">
            <p className="text-center">
              Are you sure?&nbsp;&nbsp;This action can&apos;t be undone.
            </p>
            <div className="text-right">
              <button
                type="button"
                className="btn btn-secondary px-2 py-1 ml-2"
                data-toggle="modal"
                data-target="#confirm"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary px-2 py-1 ml-2"
                onClick={() => {
                  $('#confirm').modal('hide');
                  action();
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

ConfirmAction.propTypes = {
  action: PropTypes.func.isRequired,
};

export default ConfirmAction;
