import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import validator from '../helpers/validator';
import changePasswordAction from '../redux/actions/user/changePassword';

const ChangePassword = ({
  changePasswordAction: changePassword,
  changePasswordData,
}) => {
  const history = useHistory();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_PROVIDER_TOKEN')) {
      return history.push('/login');
    }
    setSubmitting(false);
    setErrors([]);
    if (changePasswordData.status === 'error') {
      return setErrors([changePasswordData.error.message]);
    }
    if (changePasswordData.status === 'success') {
      setPasswordChanged(true);
      setOldPassword('');
      setNewPassword('');
      setErrors([]);
    }
    return undefined;
  }, [changePasswordData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { oldPassword, newPassword };
    const validationErrors = await validator(data);
    if (validationErrors.length > 0) return setErrors(validationErrors);
    setErrors([]);
    setPasswordChanged(false);
    setSubmitting(true);
    return changePassword(data);
  };

  return (
    <>
      <div className="col-8">
        <div className="form-div mt-4 p-4 text-white bg-secondary rounded">
          <h6>Update Password</h6>

          <form action="" className="mt-2">
            <div className="row">
              <div className="col-6">
                {errors.map((error, index) => (
                  <div
                    className="alert alert-danger alert-dismissible text-center medium-text"
                    role="alert"
                    key={index}
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                {passwordChanged ? (
                  <div
                    className="alert alert-warning alert-dismissible text-center medium-text"
                    role="alert"
                  >
                    Your password has been reset successfully!
                    <button
                      type="button"
                      className="close"
                      onClick={() => setPasswordChanged(false)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ) : null}
                <div className="form-group mt-3">
                  <input
                    type="password"
                    className={`form-control formInput ${
                      submitting ? 'cursor-not-allowed' : ''
                    }`}
                    placeholder="Current Password"
                    required
                    disabled={!!submitting}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </div>
                <div className="form-group mt-4">
                  <input
                    type="password"
                    className={`form-control formInput ${
                      submitting ? 'cursor-not-allowed' : ''
                    }`}
                    disabled={!!submitting}
                    placeholder="New Password"
                    required
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6 text-center">
                {submitting ? (
                  <button
                    type="submit"
                    className="btn btn-secondary py-1 px-5 mt-5 login-button"
                    disabled
                  >
                    UPDATING
                    <span
                      className="spinner-border spinner-border ml-2"
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        fontSize: '0.7rem',
                      }}
                      role="status"
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary py-1 px-5 mt-5"
                    onClick={handleSubmit}
                  >
                    UPDATE
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

ChangePassword.propTypes = {
  changePasswordAction: PropTypes.func.isRequired,
  changePasswordData: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ changePassword }) => ({
  changePasswordData: changePassword,
});

export default connect(mapStateToProps, { changePasswordAction })(
  ChangePassword
);
