import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dateRange, { findMonth } from '../../helpers/dateRange';
import monthInWords from '../../helpers/monthInWords';
import NumberCard from '../NumberCard';
import Icon from '../Icon';
import Chart from '.';
import fetchStreamsAction from '../../redux/actions/charts/fetchStreams';

const OverviewChart = ({
  profile: { monthlyStreams, totalStreams, totalRevenue },
  getTotalStreams,
  fetchStreamsAction: fetchStreams,
}) => {
  const currentDate = new Date();

  const [selectData, setSelectData] = useState([]);
  const [streamsData, setStreamsData] = useState({});
  const [fetchStatus, setFetchStatus] = useState('initial');
  const [select1, setSelect1] = useState(0);
  const [select2, setSelect2] = useState(0);

  useEffect(() => {
    if (fetchStatus === 'initial') {
      fetchStreams('provider');
      setFetchStatus('fetching');
    }

    if (getTotalStreams.status === 'clear') {
      setFetchStatus('fetching');
      setStreamsData([]);
    }

    if (getTotalStreams.status === 'fetch_success') {
      setFetchStatus('success');
      setStreamsData(getTotalStreams.results);

      const { range: receivedRange } = getTotalStreams.results;
      const range = dateRange(
        `${getTotalStreams.results.initialStreamYear}
        -
        ${getTotalStreams.results.initialStreamMonth}`,
        `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`
      );

      setSelectData(range);
      setSelect1(
        range.find(findMonth(receivedRange.fromMonth, receivedRange.fromYear))
      );
      setSelect2(
        range.find(findMonth(receivedRange.toMonth, receivedRange.toYear))
      );
    }

    if (getTotalStreams.status === 'fetch_error') {
      const { error } = getTotalStreams;
      if (error.status === 500) setFetchStatus('unknown_error');
      if (error.status === 404) setFetchStatus('no_data');
    }

    return undefined;
  }, [getTotalStreams]);

  const handleSelect1 = (e) => setSelect1(e.target.value);
  const handleSelect2 = (e) => setSelect2(e.target.value);

  const handleRefreshGraph = (e) => {
    e.preventDefault();
    if (select1 && select2) {
      const fromYear = select1.slice(select1.indexOf(' ') + 1, select1.length);
      const fromMonth = monthInWords(select1.slice(0, select1.indexOf(' ')));
      const toYear = select2.slice(select2.indexOf(' ') + 1, select2.length);
      const toMonth = monthInWords(select2.slice(0, select2.indexOf(' ')));
      fetchStreams('provider', [fromYear, fromMonth, toYear, toMonth]);
    } else {
      fetchStreams('provider');
    }
    setFetchStatus('fetching');
  };

  const chartdata = fetchStatus === 'success' ? streamsData.streams : [];

  return (
    <>
      <div className="row mt-2">
        <NumberCard col={4} count={monthlyStreams} body="Streams This Month" />
        <NumberCard col={4} count={totalStreams} body="Total Streams" />
        <NumberCard col={4} count={totalRevenue} body="Total Revenue" />
      </div>
      <div className="text-white">
        <div className="row py-3 px-4">
          <div className="col-7">
            <h5>Streams</h5>
          </div>
          <div className="col-5">
            <form>
              <div className="row">
                <div className="col">
                  <select
                    className="custom-select bg-primary text-dark border-0"
                    onChange={handleSelect1}
                    value={select1}
                  >
                    <option defaultValue value="0" disabled>
                      From
                    </option>
                    {selectData.map((select, index) => (
                      <option key={index}>{select}</option>
                    ))}
                  </select>
                </div>
                <span className="pt-2 font-weight-bold"> - </span>
                <div className="col">
                  <select
                    className="custom-select bg-primary text-dark border-0"
                    value={select2}
                    onChange={handleSelect2}
                  >
                    <option defaultValue value="0" disabled>
                      To
                    </option>
                    {selectData
                      .slice(selectData.indexOf(select1), selectData.length)
                      .map((select, index) => (
                        <option key={index} value={select}>
                          {select}
                        </option>
                      ))}
                  </select>
                </div>
                <button
                  type="button"
                  className="btn btn-secondary py-2"
                  onClick={handleRefreshGraph}
                >
                  <Icon name="refresh" height={24} width={24} color="#ffffff" />
                </button>
              </div>
            </form>
          </div>
        </div>
        <Chart type="bar" status={fetchStatus} data={chartdata} />
      </div>
    </>
  );
};

OverviewChart.propTypes = {
  fetchStreamsAction: PropTypes.func.isRequired,
  getTotalStreams: PropTypes.objectOf(PropTypes.any).isRequired,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ getTotalStreams }) => ({ getTotalStreams });

export default connect(mapStateToProps, { fetchStreamsAction })(OverviewChart);
