/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { WithContext as ReactTags } from 'react-tag-input';

const TagInput = ({ tags, setTags, suggestions, placeholder, status }) => {
  const handleAddition = (tag) => setTags([...tags, tag]);
  const handleDelete = (i) => setTags(tags.filter((tag, index) => index !== i));
  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    return setTags(newTags);
  };

  return (
    <div>
      <ReactTags
        classNames={{
          tagInputField: `${
            status === 'submitting'
              ? 'disableTagInput tagInputFieldClass cursor-not-allowed'
              : 'tagInputFieldClass'
          }`,
          selected: 'selectedClass',
          tag: 'tagClass',
          remove: 'removeTagClass',
          suggestions: 'suggestionsClass',
        }}
        inputFieldPosition="top"
        tags={tags}
        placeholder={placeholder}
        suggestions={suggestions}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
      />
    </div>
  );
};

TagInput.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTags: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default TagInput;
