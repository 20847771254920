/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import $ from 'jquery';
import validator from '../helpers/validator';
import addSetAction from '../redux/actions/sets/addSet';
import Upload from './forms/Upload';
import clearRecentAction from '../redux/actions/upload/clearRecent';

const AddSet = ({
  addSet: addedSet,
  addSetAction: addSet,
  refetch,
  artist,
  clearRecentAction: clearRecent,
}) => {
  const [status, setStatus] = useState('');
  const [errors, setErrors] = useState([]);
  const [img, setImg] = useState(null);
  const [name, setName] = useState('');
  const [type, setType] = useState(0);
  const [isReleased, setIsReleased] = useState(false);
  const [released, setReleased] = useState('');
  const [fileKey, setFileKey] = useState('');
  const [fileSaved, setFileSaved] = useState(false);
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  useEffect(() => {
    if (addedSet.status === 'success') {
      $('#musicModal').modal('hide');
      clearRecent();
      setStatus('');
      setErrors([]);
      setImg(null);
      setFileKey('');
      setFileSaved(false);
      setName('');
      setType(0);
      if (triggerRefetch) refetch();
      setTriggerRefetch(false);
    }
    if (addedSet.status === 'error') {
      setStatus('');
      return setErrors([addedSet.error.message]);
    }
    return undefined;
  }, [addedSet]);

  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  const maxDate = `${yesterday.getFullYear()}-${
    yesterday.getMonth() + 1 <= 9
      ? `0${yesterday.getMonth() + 1}`
      : yesterday.getMonth()
  }-${
    yesterday.getDate() <= 9 ? `0${yesterday.getDate()}` : yesterday.getDate()
  }`;

  const updateErrors = (error) => {
    setErrors(error);
  };

  const updateFileKey = (error) => {
    setFileKey(error);
  };

  const updateFileSaved = (saved) => {
    setFileSaved(saved);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await validator({ name });
    if (validationErrors.length > 0) return setErrors(validationErrors);

    if (!type || (isReleased && !released)) {
      return setErrors(['All fields are required']);
    }

    const payload = new FormData();
    payload.append('artist', artist);
    payload.append('name', name);
    payload.append('type', type);
    payload.append('picture', fileKey);
    payload.append('released', Date.parse(released));
    addSet(payload);
    setTriggerRefetch(true);
    setErrors([]);
    return setStatus('submitting');
  };
  return (
    <div
      className="modal fade"
      id="musicModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="musicModal"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content bg-secondary text-white border-0">
          <div className="modal-header">
            <h6 className="modal-title mx-auto text-center" id="musicModal">
              Add Music Set
            </h6>
            <span
              data-dismiss="modal"
              className="close-button cursor-pointer text-center"
            >
              &times;
            </span>
          </div>
          <div className="modal-body">
            {errors.map((error, index) => (
              <div
                className="alert alert-warning text-center"
                role="alert"
                key={index}
              >
                {error}
                <button
                  type="button"
                  className="close"
                  onClick={() => setErrors([])}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ))}
            <div className="row">
              <div className="col-md-5">
                <Upload
                  defaultValue={img}
                  updateErrors={updateErrors}
                  updateFileSaved={updateFileSaved}
                  updateFileKey={updateFileKey}
                  uploadType="set"
                  id="addSetFile"
                  accept=".png,.jpg"
                  status={status}
                />
              </div>
              <div className="col-md-7">
                <form action="" className="">
                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control formInput ${
                        status === 'submitting' ? 'cursor-not-allowed' : ''
                      }`}
                      placeholder="Title"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      disabled={status === 'submitting'}
                    />
                  </div>
                  <div className="form-group mt-4">
                    <select
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      className="custom-select text-white border-0 formSelect"
                      disabled={status === 'submitting'}
                    >
                      <option disabled defaultValue value={0}>
                        Type
                      </option>
                      <option value="Album">Album</option>
                      <option value="EP">EP</option>
                      <option value="Single">Single</option>
                    </select>
                  </div>
                  <div className="form-group text-left">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className={`custom-control-input ${
                          status === 'submitting' ? 'cursor-not-allowed' : ''
                        }`}
                        id="releasedCheck"
                        onChange={(e) => {
                          setIsReleased(e.target.checked);
                        }}
                        disabled={status === 'submitting'}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="releasedCheck"
                        style={{ fontSize: 16 }}
                      >
                        Is this set already released?
                      </label>
                    </div>
                  </div>
                  {isReleased ? (
                    <div className="form-group mt-3">
                      <input
                        type="date"
                        className={`form-control formInput ${
                          status === 'submitting' ? 'cursor-not-allowed' : ''
                        }`}
                        min="1907-01-01"
                        max={maxDate}
                        required
                        value={released}
                        style={{ fontSize: 15 }}
                        onChange={(e) => setReleased(e.target.value)}
                        disabled={status === 'submitting'}
                      />
                    </div>
                  ) : null}

                  <div className="form-group mt-4 text-center">
                    {status === 'submitting' ? (
                      <button
                        type="submit"
                        className="btn btn-secondary py-1 px-4"
                        disabled
                      >
                        Submitting
                        <span
                          className="spinner-border spinner-border ml-2"
                          style={{
                            width: '1.2rem',
                            height: '1.2rem',
                            fontSize: '0.5rem',
                          }}
                          role="status"
                          aria-hidden="true"
                        />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary py-1 px-4"
                        onClick={handleSubmit}
                        disabled={
                          !!(
                            (!fileSaved && img) ||
                            !fileKey ||
                            !name ||
                            !type ||
                            (isReleased && !released)
                          )
                        }
                      >
                        + Add Set
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AddSet.propTypes = {
  addSet: PropTypes.objectOf(PropTypes.any).isRequired,
  addSetAction: PropTypes.func.isRequired,
  clearRecentAction: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  artist: PropTypes.string.isRequired,
};

const mapStateProps = ({ addSet }) => ({ addSet });

export default connect(mapStateProps, { addSetAction, clearRecentAction })(
  AddSet
);
