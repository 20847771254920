/* eslint-disable object-curly-newline */
/* eslint-disable indent */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import Icon from './Icon';
import logOutAction from '../redux/actions/user/logout';

const SideBar = ({ profile, active, logOutAction: logOut, user }) => {
  useEffect(() => {
    if (user.status === 'logout_success') {
      return window.location.assign('/login');
    }
    return undefined;
  }, [user]);

  const handleLogOut = () => {
    localStorage.removeItem('INGOMA_PROVIDER_TOKEN');
    return logOut();
  };

  let streams;
  if (profile) {
    if (profile.monthlyStreams) {
      streams = profile.monthlyStreams.toLocaleString();
    } else {
      streams = 0;
    }
  }

  return (
    <div className="sidebar">
      <div className="logo-container">
        <img src={logo} className="sidebar-logo" alt="logo" />
      </div>
      <div className="sidebar-content">
        <div className="provider">
          <div className="provider-initials yellow">
            <b>
              {profile && profile.name !== 'Error'
                ? profile.name
                    .split(' ')
                    .map((n) => n[0])
                    .join('')
                : 'Name'}
              {}
            </b>
          </div>
          <div className="larger-text mt-1">
            {profile && profile.name !== 'Error' ? profile.name : 'Name'}
          </div>
          <div className="small-text yellow">
            {profile && profile.type !== 'Error' ? profile.name : 'Type'}
          </div>
          <div className="larger-text mt-1">{profile ? streams : 0}</div>
          <div className="small-text">Streams This Month</div>
        </div>
        <div className="sidebar-menu">
          <Link to="/" className="menu-item mb-2">
            <Icon
              name="overview"
              height={25}
              width={25}
              color={active === 'overview' ? '#E5DA2F' : '#FFFFFF'}
            />
            <span
              className={`ml-2 ${active === 'overview' ? 'yellow' : 'white'}`}
            >
              Overview
            </span>
          </Link>
          <Link to="/artists" className="menu-item mb-2">
            <Icon
              name="music"
              height={25}
              width={25}
              color={active === 'music' ? '#E5DA2F' : '#FFFFFF'}
            />
            <span className={`ml-2 ${active === 'music' ? 'yellow' : 'white'}`}>
              Music
            </span>
          </Link>
          <Link to="/payouts" className="menu-item mb-4">
            <Icon
              name="payouts"
              height={25}
              width={25}
              color={active === 'payouts' ? '#E5DA2F' : '#FFFFFF'}
            />
            <span
              className={`ml-2 ${active === 'payouts' ? 'yellow' : 'white'}`}
            >
              Payouts
            </span>
          </Link>
          <div className="hr" />
          <Link to="/settings" className="menu-item mt-3">
            <Icon
              name="settings"
              height={25}
              width={25}
              color={active === 'settings' ? '#E5DA2F' : '#FFFFFF'}
            />
            <span
              className={`ml-2 ${active === 'settings' ? 'yellow' : 'white'}`}
            >
              Settings
            </span>
          </Link>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary py-0 px-4 mb-3"
        onClick={handleLogOut}
      >
        <Icon name="logout" height={24} width={24} color="#232323" />
        Log Out
      </button>
    </div>
  );
};

SideBar.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  active: PropTypes.string.isRequired,
  logOutAction: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, { logOutAction })(SideBar);
