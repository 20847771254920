import * as yup from 'yup';

const SUPPORTED_IMG_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const SUPPORTED_AUDIO_FORMATS = ['audio/mpeg'];

const validators = {
  email: yup
    .string()
    .email('Please use a valid email')
    .required('Please enter an email'),
  name: yup
    .string()
    .min(2, 'The name must be atleast 2 characters long')
    .required('The name is required'),
  password: yup
    .string()
    .min(6, 'The password must be atleast 6 characters long'),
  oldPassword: yup
    .string()
    .min(6, 'The password must be atleast 6 characters long'),
  newPassword: yup
    .string()
    .min(6, 'The password must be atleast 6 characters long'),
  urlToken: yup
    .string()
    .matches(
      /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_.+/=]*$/,
      'The token passed is invalid'
    ),
  account_bank: yup
    .string()
    .min(3, 'The account bank must be atleast 3 characters long'),
  account_number: yup
    .string()
    .min(6, 'The account number must be atleast 6 characters long'),
  img: yup
    .mixed()
    .required('Image is required')
    .test(
      'fileFormat',
      'Only jpeg/png images are allowed',
      (value) => value && SUPPORTED_IMG_FORMATS.includes(value.type)
    ),
  file: yup
    .mixed()
    .required('Audio is required')
    .test(
      'fileFormat',
      'Please upload an mp3 file',
      (value) => value && SUPPORTED_AUDIO_FORMATS.includes(value.type)
    ),
};

export default (value) => {
  const selected = {};
  Object.keys(value).forEach((key) => {
    selected[key] = validators[key];
  });
  return yup
    .object()
    .shape(selected)
    .validate(value, { abortEarly: false })
    .catch((err) => err.errors);
};
