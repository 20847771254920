/* eslint-disable no-nested-ternary */
/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import blueBird from '../../assets/images/blue-bird.png';
import StepButtons from './StepButtons';
import validator from '../../helpers/validator';

const SignUp = ({ onNext, onPrev, step, formData, profileData, type }) => {
  const [firstname, setFirstname] = useState(formData.firstname);
  const [lastname, setLastname] = useState(formData.lastname);
  const [email, setEmail] = useState(formData.email);
  const [phoneNumber, setPhoneNumber] = useState(formData.phoneNumber);
  const [errors, setErrors] = useState([]);
  const [isStepValid, setIsStepValid] = useState(false);

  const getValidationErrors = async () => {
    if (!firstname || !lastname) return ['All fields are required'];
    return validator({ email, phoneNumber });
  };

  useEffect(() => {
    async function fetchErrors() {
      const validationErrors = await getValidationErrors();
      setIsStepValid(!validationErrors.length);
      setErrors(validationErrors.length ? validationErrors : []);
    }

    if (type === 'update' && !formData.firstname) {
      setFirstname(profileData.firstname);
      setLastname(profileData.lastname);
      setPhoneNumber(
        profileData.phoneNumber
          ? profileData.phoneNumber.slice(4, profileData.phoneNumber.length)
          : ''
      );
      setEmail(profileData.email);
    }

    fetchErrors();
  }, [
    type === 'update' ? null : firstname,
    type === 'update' ? null : lastname,
    type === 'update' ? null : phoneNumber,
    email,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      firstname,
      lastname,
      email,
      phoneNumber,
    };

    if (type === 'update') {
      if (!firstname || !lastname) {
        return setErrors(['All fields are required']);
      }
      const validationErrors = await validator({ phoneNumber });
      if (validationErrors.length > 0) return setErrors(validationErrors);
    }

    const validationErrors = getValidationErrors();
    if (validationErrors.length > 0) return setErrors(validationErrors);

    return onNext(data);
  };

  return (
    <>
      <div className="register-content mx-auto mt-3">
        <div className="row">
          <div className="col-6 div-1">
            <div className="auth-form">
              <p className="text-white">
                Please provide necessary personal information to register the
                artist&#39;s account.
              </p>
              <form className="mt-3">
                {errors.map((error, index) => (
                  <div
                    className="alert alert-warning py-2 font-smooth"
                    role="alert"
                    key={index}
                    style={{ fontSize: 15 }}
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      style={{ marginTop: '-2px', marginRight: '-12px' }}
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                <div className="form-row">
                  <div className="form-group col-6">
                    <div className="small-form-input-div mr-3">
                      <input
                        type="text"
                        className="form-control formInput"
                        placeholder="First Name"
                        value={firstname}
                        onChange={(e) => {
                          setFirstname(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <div className="small-form-input-div">
                      <input
                        type="text"
                        className="form-control formInput"
                        placeholder="Last Name"
                        value={lastname}
                        onChange={(e) => {
                          setLastname(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group col-12">
                    <input
                      type="text"
                      className="form-control formInput"
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      disabled={type === 'update'}
                    />
                  </div>
                  <div className="form-group col-12">
                    <div className="input-group">
                      <div className="input-group-prepend payment-info-grp">
                        <span className="input-group-text text-white">
                          +250
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control formInput"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                        maxLength="9"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-6 div-2">
            {type === 'update' ? (
              <div
                className="alert alert-warning py-2 font-smooth"
                role="alert"
                style={{ fontSize: 15 }}
              >
                {profileData.status === 'pending'
                  ? 'Your application is pending and is yet to be reviewed soon!'
                  : 'Your previous application was denied, Please fix the issues mentioned in the email!'}
                <button
                  type="button"
                  className="close"
                  style={{ marginTop: '-2px', marginRight: '-12px' }}
                  data-dismiss="alert"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : null}
            <div className="form-img-div">
              <img
                src={blueBird}
                alt="right-form-pic"
                className="right-form-image"
              />
            </div>
          </div>
        </div>
      </div>
      <StepButtons
        onNext={handleSubmit}
        onPrev={onPrev}
        step={step}
        disableBtn
        disableNext={!isStepValid}
      />
    </>
  );
};

SignUp.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  type: PropTypes.string,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  profileData: PropTypes.objectOf(PropTypes.any),
};

SignUp.defaultProps = {
  profileData: {},
  type: '',
};

export default SignUp;
