import React from 'react';
import PropTypes from 'prop-types';

const ProviderCard = ({ provider: { name, type } }) => (
  <div className="col-4">
    <div className="icon-div text-white bg-secondary rounded mt-4 py-3">
      <div className="provider">
        <div className="provider-initials-small yellow mx-auto">
          <b>
            {name.split(' ').map((n) => n[0]).join('')}
          </b>
        </div>
        <div className="larger-text mt-1">{name}</div>
        <div className="small-text yellow">{type}</div>
      </div>
    </div>
  </div>
);

ProviderCard.propTypes = {
  provider: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ProviderCard;
