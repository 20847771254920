/* eslint-disable no-unused-expressions */
/* eslint-disable operator-linebreak */
/* eslint-disable consistent-return */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import validator from '../../helpers/validator';
import blueBird from '../../assets/images/blue-bird.png';
import StepButtons from './StepButtons';
import Upload from './Upload';
import clearRecentAction from '../../redux/actions/upload/clearRecent';

const SignUp = ({
  onNext,
  onPrev,
  step,
  formData,
  type,
  loading,
  profileData,
  clearRecentAction: clearRecent,
}) => {
  const [profilePhotoLink, setProfilePhotoLink] = useState(
    formData.profilePhotoLink
  );
  const [name, setName] = useState(formData.name);
  const [file, setFile] = useState(formData.file);
  const [currentFile, setCurrentFile] = useState('');
  const [checked, setChecked] = useState(
    type === 'update' ? true : formData.checked
  );
  const [errors, setErrors] = useState([]);
  const [fileSaved, setFileSaved] = useState(!!profilePhotoLink);

  useEffect(() => {
    if (type === 'update' && !formData.name) {
      setProfilePhotoLink(profileData.profilePhotoLink);
      setName(profileData.name);
    }
  }, [type === 'update' ? null : name]);

  const updateIdCard = (value) => {
    setProfilePhotoLink(value);
  };

  const updateErrors = (error) => {
    setErrors(error);
  };

  const updateFileSaved = (saved) => {
    setFileSaved(saved);
  };

  const updateCurrentFile = (current) => {
    setCurrentFile(current);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearRecent();
    const imgSent = currentFile || formData.img;
    if (!name || (type !== 'update' && !file)) {
      return setErrors(['All fields are required']);
    }
    if (!checked) return setErrors(['Please check the box to proceed!']);
    const data = {
      name,
      img: imgSent,
      profilePhotoLink,
      checked,
      file,
    };

    const validationErrors = await validator({ imgSent });
    if (validationErrors.length > 0) return setErrors(validationErrors);
    return onNext(data);
  };

  const handlePrev = async (e) => {
    e.preventDefault();
    clearRecent();
    const imgSent = currentFile || formData.img;
    if (type === 'update') {
      if (profilePhotoLink || imgSent || file) {
        const data = {
          name,
          profilePhotoLink,
          img: imgSent,
          file,
        };
        return onPrev(data);
      }
    } else if (profilePhotoLink && imgSent && name && file) {
      const data = {
        name,
        profilePhotoLink,
        img: imgSent,
        checked,
        file,
      };
      return onPrev(data);
    }
    return onPrev(null);
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleFileChange = (e) => {
    const contractFile = e.target.files[0];
    if (contractFile && contractFile.size > 5000000) {
      setErrors(['File must not exceed 5 MBs']);
      return false;
    }

    e.target.files[0] ? setFile(e.target.files[0]) : null;
  };

  const displayFileName = () => {
    let fileName;
    if (type === 'update' && !file) {
      if (typeof profileData.contract !== 'undefined') {
        const ext = profileData.contract.slice(
          profileData.contract.indexOf('.', 80),
          profileData.contract.length
        );
        fileName = `${profileData.contract.slice(0, 37)}_${ext}`;
      } else {
        fileName = 'Upload contract (Required)';
      }
    } else if (file) {
      if (file.name.length > 40) {
        const o = name.split('.');
        const extension = `${o[o.length - 1]}`;
        fileName = `${file.name.slice(0, 37)}_${extension}`;
      } else {
        fileName = file.name;
      }
    } else {
      fileName = 'Upload contract (Required)';
    }
    return fileName;
  };

  const checkVal = type === 'update' ? true : checked;

  return (
    <>
      <div className="register-content mx-auto mt-3">
        <div className="row">
          <div className="col-6 div-1">
            <div className="auth-form">
              <p className="text-white">
                Please provide artist&#39;s stage name and upload your profile
                picture.
              </p>
              <form className="mt-3 id-form">
                {errors.map((error, index) => (
                  <div
                    className="alert alert-warning py-2 font-smooth"
                    role="alert"
                    key={index}
                    style={{ fontSize: 15 }}
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      style={{ marginTop: '-2px', marginRight: '-12px' }}
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                <div className="form-group col-12">
                  <input
                    type="text"
                    className="form-control formInput"
                    placeholder="Artist Name"
                    value={name}
                    onChange={handleChange}
                  />
                </div>
                <Upload
                  defaultValue={formData.img}
                  defaultValue2={profilePhotoLink}
                  updateIdCard={updateIdCard}
                  updateErrors={updateErrors}
                  updateFileSaved={updateFileSaved}
                  updateCurrentFile={updateCurrentFile}
                  uploadType={type === 'update' ? 'tempProfPic' : 'signupProf'}
                  id={type === 'update' ? 'tempProfPic' : 'signupProfPic'}
                  accept=".png,.jpg"
                />
                <p className="text-white" style={{ fontSize: 15 }}>
                  <span className="text-primary mr-2">N.B:</span>
                  If the person filling this information is not the artist,
                  please upload an official document/contract allowing you to
                  represent them below
                </p>
                <div className="form-group col-12">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input bg-dark"
                      id="contractFile"
                      onChange={handleFileChange}
                    />
                    <label className="custom-file-label" htmlFor="contractFile">
                      {displayFileName()}
                    </label>
                  </div>
                </div>
                {type === 'update' ? null : (
                  <div className="form-group signup-checkBox">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="agreeTerms"
                        name="formCheck"
                        checked={checked}
                        onChange={() => {
                          setChecked(!checked);
                        }}
                      />
                      <label
                        className="custom-control-label text-white"
                        htmlFor="agreeTerms"
                      >
                        By clicking Sign Up, you agree to the
                        <a
                          href="https://artist.ingoma.app/terms-and-conditions"
                          rel="noreferrer"
                          target="_blank"
                          className="mx-1 text-primary"
                        >
                          Terms and Conditions
                        </a>
                      </label>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
          <div className="col-6 div-2">
            {type === 'update' ? (
              <div
                className="alert alert-warning py-2 font-smooth"
                role="alert"
                style={{ fontSize: 15 }}
              >
                {profileData.status === 'pending'
                  ? 'Your application is pending and is yet to be reviewed soon!'
                  : 'Your previous application was denied, Please fix the issues mentioned in the email!'}
                <button
                  type="button"
                  className="close"
                  style={{ marginTop: '-2px', marginRight: '-12px' }}
                  data-dismiss="alert"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : null}
            <div className="form-img-div">
              <img
                src={blueBird}
                alt="right-form-pic"
                className="right-form-image"
              />
            </div>
          </div>
        </div>
      </div>
      <StepButtons
        onNext={handleSubmit}
        onPrev={handlePrev}
        step={step}
        loading={loading}
        disableBtn={!!(!fileSaved && currentFile)}
        disableNext={
          !!(
            (!fileSaved && currentFile) ||
            !profilePhotoLink ||
            !name ||
            (type !== 'update' && !file) ||
            !checkVal
          )
        }
        type={type ? 'update' : ''}
      />
    </>
  );
};

SignUp.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  clearRecentAction: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  profileData: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};

SignUp.defaultProps = {
  profileData: {},
  type: '',
};

const mapStateToProps = ({ getUploadUrl, saveFile }) => ({
  getUploadUrl,
  saveFile,
});

export default connect(mapStateToProps, {
  clearRecentAction,
})(SignUp);
