import axios, { config } from '..';

export default async (active) => {
  try {
    const response = await axios.get('/artists/all', config);
    return response.data.data.reduce((result, { _id, name }) => {
      if (active !== _id) result.push({ id: _id, text: name });
      return result;
    }, []);
  } catch (error) {
    return error;
  }
};
