/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import $ from 'jquery';
import TagInput from './TagInput';
import addTrackAction from '../redux/actions/tracks/addTrack';
import fetchArtists from '../redux/actions/artists/getPlatformArtists';
import Upload from './forms/Upload';
import clearRecentAction from '../redux/actions/upload/clearRecent';

const AddTrack = ({
  addTrack: addedTrack,
  addTrackAction: addTrack,
  refetch,
  artist,
  set,
  clearRecentAction: clearRecent,
}) => {
  const [status, setStatus] = useState('initial');
  const [errors, setErrors] = useState([]);
  const [img, setImg] = useState(null);
  const [title, setTitle] = useState('');
  const [genre, setGenre] = useState(0);
  const [tags, setTags] = useState([]);
  const [artists, setArtists] = useState([]);
  const [fileKey, setFileKey] = useState('');
  const [fileSaved, setFileSaved] = useState(false);

  const getArtists = async () => {
    const platformArtists = await fetchArtists(artist);
    setArtists(platformArtists);
    setStatus('');
  };

  useEffect(() => {
    if (status === 'initial') getArtists();

    if (addedTrack.status === 'success') {
      $('#addTrackModal').modal('hide');
      clearRecent();
      setStatus('');
      setErrors([]);
      setImg(null);
      setFileKey('');
      setFileSaved(false);
      setTitle('');
      setGenre(0);
      refetch();
    }
    if (addedTrack.status === 'error') {
      setStatus('failed');
      return setErrors([addedTrack.error.message]);
    }
    return undefined;
  }, [addedTrack]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !genre) return setErrors(['The title & genre are required']);
    const payload = new FormData();
    payload.append('musicSet', set);
    payload.append('title', title);
    payload.append('genre', genre);
    payload.append('key', fileKey);
    tags.forEach((tag) => {
      payload.append('featuring', tag.id);
    });
    addTrack(payload);
    setErrors([]);
    return setStatus('submitting');
  };

  const updateErrors = (error) => {
    setErrors(error);
  };

  const updateFileKey = (error) => {
    setFileKey(error);
  };

  const updateFileSaved = (saved) => {
    setFileSaved(saved);
  };

  return (
    <div
      className="modal fade"
      id="addTrackModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTrackModal"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content bg-secondary text-white border-0">
          <div className="modal-header">
            <h6 className="modal-title mx-auto text-center" id="addTrackModal">
              Add Track
            </h6>
            <span
              data-dismiss="modal"
              className="close-button cursor-pointer text-center"
            >
              &times;
            </span>
          </div>
          <div className="modal-body">
            {errors.map((error, index) => (
              <div
                className="alert alert-warning text-center"
                role="alert"
                key={index}
              >
                {error}
                <button
                  type="button"
                  className="close"
                  onClick={() => setErrors([])}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ))}
            <div className="row">
              <div className="col-md-5">
                <Upload
                  defaultValue={img}
                  updateErrors={updateErrors}
                  updateFileSaved={updateFileSaved}
                  updateFileKey={updateFileKey}
                  uploadType="track"
                  id="addTrackFile"
                  accept=".mp3"
                  status={status}
                />
              </div>
              <div className="col-md-7">
                <form action="" className="">
                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control formInput ${
                        status === 'submitting' ? 'cursor-not-allowed' : ''
                      }`}
                      placeholder="Track Title"
                      required
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      disabled={status === 'submitting'}
                    />
                  </div>
                  <div className="form-group">
                    <select
                      value={genre}
                      onChange={(e) => setGenre(e.target.value)}
                      className="custom-select text-white border-0 formSelect"
                      disabled={status === 'submitting'}
                    >
                      <option disabled defaultValue value={0}>
                        Genre
                      </option>
                      <option value="R&B">R&B</option>
                      <option value="Kinyatrap">Kinyatrap</option>
                      <option value="Hip Hop">Hip Hop</option>
                      <option value="Pop">Pop</option>
                      <option value="Pop">Reggae</option>
                      <option value="Afrobeat">Afrobeat</option>
                      <option value="Gospel">Gospel</option>
                      <option value="Karahanyuze">Karahanyuze</option>
                      <option value="Dance">Dance</option>
                    </select>
                  </div>
                  <div className="form-group" disabled>
                    <TagInput
                      tags={tags}
                      setTags={setTags}
                      suggestions={artists}
                      placeholder="Enter featured artist"
                      status={status}
                    />
                  </div>
                  <div className="form-group mt-2 text-center">
                    {status === 'submitting' ? (
                      <button
                        type="submit"
                        className="btn btn-secondary py-1 px-4"
                        disabled
                      >
                        Submitting
                        <span
                          className="spinner-border spinner-border ml-2"
                          style={{
                            width: '1.2rem',
                            height: '1.2rem',
                            fontSize: '0.5rem',
                          }}
                          role="status"
                          aria-hidden="true"
                        />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary py-1 px-4"
                        onClick={handleSubmit}
                        disabled={
                          !!(
                            (!fileSaved && img) ||
                            !fileKey ||
                            !title ||
                            !genre
                          )
                        }
                      >
                        + Add Track
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AddTrack.propTypes = {
  addTrack: PropTypes.objectOf(PropTypes.any).isRequired,
  clearRecentAction: PropTypes.func.isRequired,
  addTrackAction: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  artist: PropTypes.string.isRequired,
  set: PropTypes.string.isRequired,
};

const mapStateProps = ({ addTrack }) => ({ addTrack });

export default connect(mapStateProps, { addTrackAction, clearRecentAction })(
  AddTrack
);
