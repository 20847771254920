import { combineReducers } from 'redux';

import user from './user';
import artists from './artists';
import tracks from './tracks';
import charts from './charts';
import sets from './sets';
import payments from './payments';
import upload from './upload';

export default combineReducers({
  ...user,
  ...tracks,
  ...artists,
  ...charts,
  ...sets,
  ...payments,
  ...upload,
});
