/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';

const MusicCard = ({
  music: { picture, name, type, monthlyStreams, allTimeStreams },
  goToSet,
}) => (
  <div className="col-lg-4 px-4 py-2">
    <div
      className="row py-2 text-white bg-secondary rounded cursor-pointer"
      onClick={() => goToSet()}
      onKeyPress={goToSet}
      role="link"
      tabIndex={0}
    >
      <div className="col-3 align-self-center text-left">
        <img
          className="rounded"
          width="70px"
          height="70px"
          style={{ objectFit: 'cover' }}
          src={picture}
          alt=""
          srcSet=""
        />
      </div>
      <div className="col-9 text-left pl-2">
        <p className="my-0 py-0">{name}</p>
        <caption className="my-0 py-0">{type}</caption>
        <p className="my-0 py-0">
          {`${monthlyStreams.toLocaleString()} Total, ${allTimeStreams.toLocaleString()} This Month`}
        </p>
      </div>
    </div>
  </div>
);

MusicCard.propTypes = {
  music: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  picture: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  monthlyStreams: PropTypes.number.isRequired,
  allTimeStreams: PropTypes.number.isRequired,
  goToSet: PropTypes.func.isRequired,
};

export default MusicCard;
