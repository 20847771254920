import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import validator from '../helpers/validator';
import newPasswordAction from '../redux/actions/user/newPassword';

const NewPassword = ({
  newPasswordAction: newPassword,
  newPassword: setNewPassword,
  location,
}) => {
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [resetSuccess, SetResetSuccess] = useState(false);

  const initialPage = location.search.indexOf('?token=') !== -1;
  const successPage = location.search.indexOf('?reset=success') !== -1;

  useEffect(() => {
    if (localStorage.getItem('INGOMA_PROVIDER_TOKEN')) {
      localStorage.removeItem('INGOMA_PROVIDER_TOKEN');
    }
    if (!initialPage && !(successPage && resetSuccess)) {
      return history.push('/login');
    }
    setSubmitting(false);
    if (setNewPassword.status === 'error') {
      return setErrors([setNewPassword.error.message]);
    }
    if (setNewPassword.status === 'success') {
      SetResetSuccess(true);
      return history.replace(`${location.pathname}?reset=success`);
    }
    return undefined;
  }, [setNewPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      password,
    };
    const urlToken = location.search.slice(
      location.search.indexOf('?token=') + 7,
      location.search.length
    );
    const validationErrors = await validator({ ...data, urlToken });
    if (validationErrors.length > 0) return setErrors(validationErrors);
    setErrors([]);
    setSubmitting(true);
    return newPassword(data, urlToken);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row sign-container justify-content-center">
          <div
            className="col-lg-12 py-5 text-white"
            style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
          >
            <div className="row">
              <div className="col">
                <div className="pwd-wrapper">
                  <div className="text-center bg-dark pwd-container px-3 py-4 pb-2">
                    <h4 className="text-white" style={{ fontSize: 30 }}>
                      Ingoma
                    </h4>
                    <h5 className="text-white" style={{ fontSize: 16 }}>
                      Password Reset
                    </h5>
                    <div className="mt-5 px-4">
                      {errors.map((error, index) => (
                        <div
                          className="alert alert-danger"
                          role="alert"
                          key={index}
                        >
                          {error}
                          <button
                            type="button"
                            className="close"
                            onClick={() => setErrors([])}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      ))}
                      {resetSuccess ? (
                        <>
                          <div className="alert alert-warning" role="alert">
                            Your Password has been successfully reset, Proceed
                            to login with your new password.
                          </div>
                          <p className="text-center my-4">
                            <Link className="text-primary" to="/login">
                              Go to Log In
                            </Link>
                          </p>
                        </>
                      ) : (
                        <form action="">
                          <div className="form-group">
                            <input
                              type="password"
                              className={`form-control form-control-lg authInput ${
                                submitting ? 'cursor-not-allowed' : ''
                              }`}
                              disabled={!!submitting}
                              placeholder="New Password"
                              onChange={(e) => setPassword(e.target.value)}
                              value={password}
                              required
                            />
                          </div>
                          <div className="form-group">
                            {submitting ? (
                              <button
                                type="submit"
                                className="btn btn-secondary px-5 py-2 mb-2 mt-4"
                                disabled
                              >
                                Resetting
                                <span
                                  className="spinner-border spinner-border ml-2"
                                  style={{
                                    width: '1.5rem',
                                    height: '1.5rem',
                                    fontSize: '0.7rem',
                                  }}
                                  role="status"
                                  aria-hidden="true"
                                />
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary px-5 py-2 mb-2 mt-4"
                                style={{ color: '#000' }}
                                onClick={handleSubmit}
                              >
                                Reset
                              </button>
                            )}
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

NewPassword.propTypes = {
  newPasswordAction: PropTypes.func.isRequired,
  newPassword: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ newPassword }) => ({ newPassword });

export default connect(mapStateToProps, { newPasswordAction })(NewPassword);
