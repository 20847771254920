/* eslint-disable no-console */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable operator-linebreak */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imageCompression from 'browser-image-compression';
import idCard from '../../assets/images/idCard.png';
import musicPlaceholder from '../../assets/images/music.png';
import avatar from '../../assets/images/user.png';
import ProgressBar from '../ProgressBar';
import uploadAction from '../../redux/actions/upload/getUploadUrl';

const Upload = ({
  defaultValue,
  defaultValue2,
  changeValue,
  updateIdCard,
  updateErrors,
  updateFileSaved,
  updateFileKey,
  uploadType,
  updateCurrentFile,
  id,
  accept,
  status,
  uploadAction: upload,
  getUploadUrl: getUpload,
}) => {
  const [img, setImg] = useState(changeValue ? defaultValue2 : defaultValue);
  const [currentFile, setCurrentFile] = useState('');
  const [stopUpload, setStopUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileSaved, setFileSaved] = useState(false);
  const [currentBtn, setCurrentBtn] = useState('');

  const uploadFile = (url, file, savedKey) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            if (currentBtn === uploadType) {
              setFileSaved(true);
              updateFileSaved(true);
              const savedUrl =
                uploadType === 'track'
                  ? savedKey
                  : url.slice(0, url.indexOf('?'));
              if (updateIdCard) {
                updateIdCard(savedUrl);
              }
              if (updateFileKey) {
                updateFileKey(savedUrl);
              }
            }
            resolve(xhr);
          } else {
            reject(xhr);
          }
        }
      };
      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const percentComplete = (e.loaded / file.size) * 100;
          setUploadProgress(Math.round(percentComplete));
        }
      };
      xhr.open('PUT', url);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    });
  };

  useEffect(() => {
    if (getUpload.status === 'success') {
      const url = getUpload.results.data;
      if (!stopUpload) {
        uploadFile(url, currentFile, getUpload.results.key);
        setStopUpload(true);
      }
    }

    if (getUpload.status === 'error') {
      updateErrors([getUpload.error.message]);
    }

    return undefined;
  }, [getUpload]);

  const getUploadUrl = (fileSent) => {
    const { name, type } = fileSent;
    const o = name.split('.');
    const extension = `${o[o.length - 1]}`;
    const data = {
      type,
      extension,
    };

    upload(data);
  };

  const getFile = (files) => {
    if (!files) return;
    getUploadUrl(files);
  };

  const handleFileChange = async (e) => {
    if (currentFile) {
      setCurrentFile('');
      if (updateCurrentFile) {
        updateCurrentFile('');
      }
      setFileSaved(false);
      updateFileSaved(false);
      setStopUpload(false);
      setUploadProgress(0);
    }

    const imageFile = e.target.files[0];

    if (uploadType === 'track') {
      if (imageFile) {
        if (!imageFile.name.match(/\.(mp3)$/i)) {
          updateErrors(['Only mp3 audios are allowed!']);
          return false;
        }
      }
    } else if (imageFile) {
      if (!imageFile.name.match(/\.(jpe?g|png)$/i)) {
        updateErrors(['Only jpeg/jpg/png images are allowed!']);
        return false;
      }
    }

    if (uploadType === 'track') {
      e.target.files[0] ? setImg(e.target.files[0]) : null;
      setCurrentFile(e.target.files[0]);
      if (updateCurrentFile) {
        updateCurrentFile(e.target.files[0]);
      }
      getFile(e.target.files[0]);
    } else {
      const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const imgFile = e.target.files[0] ? e.target.files[0] : null;
      try {
        const compressedFile = await imageCompression(imgFile, options);

        imgFile ? setImg(compressedFile) : null;
        setCurrentFile(compressedFile);
        if (updateCurrentFile) {
          updateCurrentFile(compressedFile);
        }
        getFile(compressedFile);
      } catch (error) {
        console.log(error);
      }
    }

    return setCurrentBtn(uploadType);
  };

  const currentProgress = () => {
    if (currentFile) {
      if (!uploadProgress) {
        return (
          <>
            <span
              style={{ fontSize: 15 }}
              className="text-center d-block text-white"
            >
              Starting the upload...
            </span>
            <span className="spinner-border spinner-border-sm d-block text-primary mx-auto" />
          </>
        );
      }
      if (uploadProgress > 0 && uploadProgress < 100) {
        return (
          <>
            <span
              style={{ fontSize: 15 }}
              className="text-center d-block text-white"
            >
              Uploading File...
            </span>
            <ProgressBar progress={uploadProgress - 1} />
          </>
        );
      }

      if (uploadProgress === 100 && !fileSaved) {
        return (
          <>
            <span
              style={{ fontSize: 15 }}
              className="text-center d-block text-white"
            >
              Saving File...
            </span>
            <span className="spinner-border spinner-border-sm d-block text-primary mx-auto" />
          </>
        );
      }
      if (fileSaved) {
        return (
          <>
            <span
              style={{ fontSize: 15 }}
              className="text-center d-block text-white"
            >
              <FontAwesomeIcon
                icon={['fas', 'check-circle']}
                className="mr-2 text-white"
              />
              File Uploaded Successfully
            </span>
          </>
        );
      }
      return <></>;
    }
    return undefined;
  };

  const imgClass = {
    width: (val) => {
      if (val === 'set' || val === 'track') {
        const cName1 = 120;
        return cName1;
      }
      if (val === 'signup' || val === 'tempFile') {
        const cName1 = img === null ? 180 : 280;
        return cName1;
      }
      if (val === 'updateArtist') {
        const cName1 = 160;
        return cName1;
      }
      if (val === 'updateCover') {
        const cName1 = 320;
        return cName1;
      }
      if (val === 'tempProfPic' || val === 'signupProf') {
        const cName1 = 160;
        return cName1;
      }
      return '';
    },
    height: (val) => {
      if (val === 'set' || val === 'track') {
        const cName2 = 120;
        return cName2;
      }
      if (val === 'signup' || val === 'tempFile') {
        const cName2 = img === null ? 180 : 200;
        return cName2;
      }
      if (val === 'updateArtist') {
        const cName2 = 160;
        return cName2;
      }
      if (val === 'updateCover') {
        const cName2 = 160;
        return cName2;
      }
      if (val === 'tempProfPic' || val === 'signupProf') {
        const cName2 = 160;
        return cName2;
      }
      return '';
    },
    imgSrc: (val) => {
      if (val === 'set' || val === 'track') {
        return musicPlaceholder;
      }
      if (val === 'signup') {
        return idCard;
      }
      if (val === 'updateSetFile') {
        return defaultValue2 || musicPlaceholder;
      }
      if (val === 'updateArtistFile') {
        return defaultValue2 || avatar;
      }
      if (val === 'updateCoverFile') {
        return defaultValue2 || avatar;
      }
      if (val === 'tempFile') {
        return defaultValue2 || idCard;
      }
      if (val === 'tempProfPic') {
        return defaultValue2 || avatar;
      }
      if (val === 'updateArtist' || val === 'signupProf') {
        return avatar;
      }
      return '';
    },
  };

  // const fileName = img ? img.name : '';

  let fileName;
  if (img) {
    const fetchImg = img.name;
    if (fetchImg.length > 55) {
      fileName = `${fetchImg.slice(0, 50)}__.mp3`;
    } else {
      fileName = fetchImg;
    }
  } else {
    fileName = '';
  }

  return (
    <>
      <div className="form-group">
        <div
          className={`${
            uploadType === 'set' || uploadType === 'track'
              ? 'img-preview-set'
              : uploadType === 'tempProfPic' || uploadType === 'signupProf'
              ? 'art-info-pic-preview'
              : 'img-preview'
          }  bg-light-dark text-white p-3 text-center`}
        >
          {img && uploadType === 'track' ? (
            <p className="text-white song-indicator py-3 mb-3">{fileName}</p>
          ) : (
            <img
              src={
                img
                  ? URL.createObjectURL(img)
                  : imgClass.imgSrc(changeValue ? id : uploadType)
              }
              alt="Artist Upload Pic"
              height={imgClass.height(uploadType)}
              width={imgClass.width(uploadType)}
            />
          )}
          <input
            type="file"
            id={id}
            name="user[image]"
            multiple={false}
            required
            onChange={handleFileChange}
            accept={accept}
            disabled={
              !!((!fileSaved && currentFile) || status === 'submitting')
            }
          />
          <label
            htmlFor={id}
            className={`file-label mt-2 ${
              (!fileSaved && currentFile) || status === 'submitting'
                ? 'cursor-not-allowed bg-secondary text-light-dark'
                : 'cursor-pointer bg-primary text-dark'
            }`}
          >
            {uploadType === 'track' ? 'Select Song' : 'Select Image'}
          </label>
        </div>
      </div>
      <div className="form-group">{currentProgress()}</div>
    </>
  );
};

Upload.propTypes = {
  defaultValue: PropTypes.objectOf(PropTypes.any),
  defaultValue2: PropTypes.string,
  changeValue: PropTypes.bool,
  status: PropTypes.string,
  uploadType: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  uploadAction: PropTypes.func.isRequired,
  updateIdCard: PropTypes.func,
  updateErrors: PropTypes.func.isRequired,
  updateFileSaved: PropTypes.func.isRequired,
  updateCurrentFile: PropTypes.func,
  updateFileKey: PropTypes.func,
  getUploadUrl: PropTypes.objectOf(PropTypes.any).isRequired,
};

Upload.defaultProps = {
  defaultValue: null,
  defaultValue2: null,
  updateIdCard: null,
  updateFileKey: null,
  changeValue: false,
  updateCurrentFile: null,
  status: '',
};

const mapStateToProps = ({ getUploadUrl, saveFile }) => ({
  getUploadUrl,
  saveFile,
});

export default connect(mapStateToProps, {
  uploadAction,
})(Upload);
