import getArtists from './fetchArtists';
import singleArtist from './singleArtist';
import addArtist from './addArtist';
import updateArtist from './updateArtist';

export default {
  getArtists,
  singleArtist,
  addArtist,
  updateArtist,
};
