import axios from '..';
import { GET_URL_SUCCESS, GET_URL_FAILED } from '../../actionTypes';

export default (body) => async (dispatch) => {
  try {
    const response = await axios.post('/uploads/get-url', body);
    const {
      data: { message, data },
    } = response;

    dispatch({
      type: GET_URL_SUCCESS,
      message,
      results: data,
    });
  } catch (err) {
    let error = {};
    if (err.response) {
      const {
        data: { status, message },
      } = err.response;
      error = { status, message };
    } else {
      error = {
        status: 500,
        message: err.message,
      };
    }

    dispatch({ type: GET_URL_FAILED, error });
  }
};
