import axios, { config } from '..';

export default async () => {
  try {
    const response = await axios.get('/providers/profile ', config);
    return response.data.data;
  } catch (error) {
    return error;
  }
};
