import React from 'react';
import PropTypes from 'prop-types';
import withSidebar from '../withSidebar';
import ChangePassword from '../components/ChangePassword';
import ProviderCard from '../components/ProviderCard';
import PaymentInfo from '../components/PaymentInfo';

const Settings = ({ profile }) => (
  <>
    <div className="row">
      <ChangePassword />
      <ProviderCard provider={profile} />
      <PaymentInfo provider={profile} />
    </div>
  </>
);

Settings.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withSidebar(Settings, 'settings');
