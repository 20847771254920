import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import store from '../redux/store';

import Signin from './Login';
import ForgotPassword from './ForgotPassword';
import Overview from './Overview';
import Artists from './Artists';
import Artist from './Artist';
import Set from './Set';
import Payouts from './Payouts';
import Settings from './Settings';
import NotFound from './NotFound';
import NewPassword from './NewPassword';
import AddArtist from './artists/AddArtist';
import AddSuccess from './artists/AddSuccess';
import UpdateTempProfile from './artists/UpdateTempProfile';
import TempUpdateSuccess from './artists/TempUpdateSuccess';

library.add(fab, fas);

export default function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Overview} />
          <Route exact path="/artists" component={Artists} />
          <Route exact path="/artists/new" component={AddArtist} />
          <Route exact path="/artists/new/success" component={AddSuccess} />
          <Route
            exact
            path="/artists/:id/application/update"
            component={UpdateTempProfile}
          />
          <Route
            exact
            path="/artists/:id/application/update/success"
            component={TempUpdateSuccess}
          />
          <Route exact path="/artists/:id" component={Artist} />
          <Route exact path="/sets/:id" component={Set} />
          <Route exact path="/payouts" component={Payouts} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/login" component={Signin} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/new-password" component={NewPassword} />

          <Route exact path="/*" component={NotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}
