import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withSidebar from '../withSidebar';
import TopTracks from '../components/TopTracks';
import OverviewChart from '../components/Charts/OverviewChart';
import Loader from '../components/Loader';
import Error from '../components/Error';
import fetchPopularAction from '../redux/actions/tracks/fetchPopular';

const Overview = ({ popular, fetchPopularAction: fetchPopular, profile }) => {
  const history = useHistory();
  const [topTracks, setTopTracks] = useState([]);
  const [status, setStatus] = useState('initial');

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_PROVIDER_TOKEN')) {
      return history.push('/login');
    }
    if (status === 'initial') {
      fetchPopular();
      setStatus('fetching');
    }

    if (popular.status === 'fetch_popular_success') {
      setStatus('success');
      setTopTracks(popular.results);
    }

    if (popular.status === 'fetch_popular_error') {
      const { error } = popular;
      if (error.status === 500) setStatus('unknown_error');
      if (error.status === 404) setStatus('no_data');
    }

    return undefined;
  });

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'no_data':
        data = (
          <Error
            title="No data found!"
            description="There appears to be no streams in this period!"
          />
        );
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  return (
    <div>
      <div className="py-3">
        <OverviewChart profile={profile} />
      </div>
      <h5 className="text-white pt-4 pb-3">Top Tracks in the last 30 days</h5>
      <DisplayData>
        <TopTracks data={topTracks} />
      </DisplayData>
    </div>
  );
};

Overview.propTypes = {
  fetchPopularAction: PropTypes.func.isRequired,
  popular: PropTypes.objectOf(PropTypes.any).isRequired,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ popular }) => ({ popular });

export default connect(mapStateToProps, { fetchPopularAction })(
  withSidebar(Overview, 'overview')
);
