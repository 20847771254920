/* eslint-disable operator-linebreak */
/* eslint-disable consistent-return */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import validator from '../../helpers/validator';
import blueBird from '../../assets/images/blue-bird.png';
import StepButtons from './StepButtons';
import Upload from './Upload';
import clearRecentAction from '../../redux/actions/upload/clearRecent';

const SignUp = ({
  onNext,
  onPrev,
  step,
  formData,
  type,
  profileData,
  clearRecentAction: clearRecent,
}) => {
  const [nationalIdCard, setNationalIdCard] = useState(formData.nationalIdCard);
  const [currentFile, setCurrentFile] = useState('');
  const [errors, setErrors] = useState([]);
  const [fileSaved, setFileSaved] = useState(!!nationalIdCard);

  useEffect(() => {
    if (type === 'update' && !formData.nationalIdCard) {
      setNationalIdCard(profileData.nationalIdCard);
    }
  });

  const updateIdCard = (value) => {
    setNationalIdCard(value);
  };

  const updateErrors = (error) => {
    setErrors(error);
  };

  const updateFileSaved = (saved) => {
    setFileSaved(saved);
  };

  const updateCurrentFile = (current) => {
    setCurrentFile(current);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearRecent();
    const imgSent = currentFile || formData.img;
    const data = {
      img: imgSent,
      nationalIdCard,
    };
    const validationErrors = await validator({ imgSent });
    if (validationErrors.length > 0) return setErrors(validationErrors);
    return onNext(data);
  };

  const handlePrev = async (e) => {
    e.preventDefault();
    clearRecent();
    const imgSent = currentFile || formData.img;
    if (nationalIdCard && imgSent) {
      const data = {
        img: imgSent,
        nationalIdCard,
      };
      return onPrev(data);
    }
    return onPrev(null);
  };

  return (
    <>
      <div className="register-content mx-auto mt-3">
        <div className="row">
          <div className="col-6 div-1">
            <div className="auth-form">
              <p className="text-white">
                Please upload artist&#39;s scanned National Identification card
                to verify his/her identity
              </p>
              <form className="mt-3 id-form">
                {errors.map((error, index) => (
                  <div
                    className="alert alert-warning py-2 font-smooth"
                    role="alert"
                    key={index}
                    style={{ fontSize: 15 }}
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                <Upload
                  defaultValue={formData.img}
                  defaultValue2={nationalIdCard}
                  updateIdCard={updateIdCard}
                  updateErrors={updateErrors}
                  updateFileSaved={updateFileSaved}
                  updateCurrentFile={updateCurrentFile}
                  uploadType={type === 'update' ? 'tempFile' : 'signup'}
                  id={type === 'update' ? 'tempFile' : 'signupFile'}
                  accept=".png,.jpg"
                />
              </form>
            </div>
          </div>
          <div className="col-6 div-2">
            {type === 'update' ? (
              <div
                className="alert alert-warning py-2 font-smooth"
                role="alert"
                style={{ fontSize: 15 }}
              >
                {profileData.status === 'pending'
                  ? 'Your application is pending and is yet to be reviewed soon!'
                  : 'Your previous application was denied, Please fix the issues mentioned in the email!'}
                <button
                  type="button"
                  className="close"
                  style={{ marginTop: '-2px', marginRight: '-12px' }}
                  data-dismiss="alert"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : null}
            <div className="form-img-div">
              <img
                src={blueBird}
                alt="right-form-pic"
                className="right-form-image"
              />
            </div>
          </div>
        </div>
      </div>
      <StepButtons
        onNext={handleSubmit}
        onPrev={handlePrev}
        step={step}
        disableBtn={!!(!fileSaved && currentFile)}
        disableNext={!!((!fileSaved && currentFile) || !nationalIdCard)}
      />
    </>
  );
};

SignUp.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  clearRecentAction: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  profileData: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};

SignUp.defaultProps = {
  profileData: {},
  type: '',
};

const mapStateToProps = ({ getUploadUrl, saveFile }) => ({
  getUploadUrl,
  saveFile,
});

export default connect(mapStateToProps, {
  clearRecentAction,
})(SignUp);
