import React from 'react';
import PropTypes from 'prop-types';

const BreadCrump = ({ path }) => (
  <div className="bg-secondary px-4 py-2 rounded text-white">
    <h5 className="my-0">{path.charAt(0).toUpperCase() + path.slice(1)}</h5>
    <p className="my-0">
      Ingoma /
      {` ${path.charAt(0).toUpperCase() + path.slice(1)}`}
    </p>
  </div>
);

BreadCrump.propTypes = {
  path: PropTypes.string.isRequired,
};

export default BreadCrump;
