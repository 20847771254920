/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import getPaidAction from '../redux/actions/payments/getPaid';

const Modal = ({
  getPaidAction: addAction,
  getPaid,
  bank,
  refetch,
  updateBalance,
}) => {
  const history = useHistory();
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(false);
    setErrors([]);

    if (getPaid.status === 'success') {
      setLoading(false);
      $('#getPaidModal').modal('hide');
      setErrors([]);
      refetch();
      updateBalance(amount);
      setAmount('');
    }
    if (getPaid.status === 'error') {
      setLoading(false);
      return setErrors([getPaid.error.message]);
    }

    return undefined;
  }, [getPaid]);

  const handleSubmit = () => {
    const data = { amount };
    if (!amount) {
      return setErrors(["Amount can't be empty!"]);
    }

    if (amount < 5000) {
      return setErrors(['Amount must be greater than 5000 RWF!']);
    }
    setErrors([]);
    setLoading(true);
    return addAction(data);
  };

  const closeModal = () => {
    $('#getPaidModal').modal('hide');
  };

  const handleRedirect = () => {
    $('#getPaidModal').modal('hide');
    return history.push('/settings');
  };

  return (
    <>
      <div
        className="modal fade"
        id="getPaidModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="getPaidModal"
        aria-hidden="true"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ width: '100%' }}
          >
            <div
              className="modal-content bg-secondary text-white border-0 artist-modal"
              style={{ margin: 0 }}
            >
              <div className="modal-header">
                <h6
                  className="modal-title mx-auto text-center"
                  id="getPaidModal"
                >
                  Request Payout
                </h6>
                <span
                  onClick={closeModal}
                  className="close-button cursor-pointer text-center"
                >
                  &times;
                </span>
              </div>
              <div className="px-3 pt-0 modal-body">
                {errors.map((error, index) => (
                  <div
                    className="alert alert-danger text-center my-2"
                    role="alert"
                    key={index}
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                {bank !== 'MPS' ? (
                  <div
                    className="alert alert-warning text-center mt-2 mb-3"
                    role="alert"
                    style={{ fontSize: 15 }}
                  >
                    Payouts to banks are temporarily not working, Please switch
                    to MoMo meanwhile.
                    <Link
                      to="#"
                      className="ml-1 alert-link"
                      onClick={handleRedirect}
                    >
                      Update info
                    </Link>
                    <button
                      type="button"
                      className="close"
                      style={{ marginTop: '-2px', marginRight: '-12px' }}
                      data-dismiss="alert"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ) : null}
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control authInput"
                      placeholder="Enter amount"
                      style={{ fontSize: 15 }}
                      onChange={(e) => setAmount(e.target.value)}
                      value={amount}
                      required
                    />
                  </div>
                </form>
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-primary px-3 py-1 ml-2 mt-3"
                    onClick={handleSubmit}
                    disabled={!!(loading || bank !== 'MPS')}
                  >
                    Get Paid
                    {loading ? (
                      <span className="spinner-border spinner-border-sm text-dark ml-2" />
                    ) : null}
                  </button>
                  <p className="mt-3 text-white ml-2" style={{ fontSize: 14 }}>
                    <span className="text-primary mr-1">N.B:</span>
                    The fee for your transaction is 500 RWF.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  bank: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  updateBalance: PropTypes.func.isRequired,
  getPaidAction: PropTypes.func.isRequired,
  getPaid: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ getPaid }) => ({ getPaid });

export default connect(mapStateToProps, { getPaidAction })(Modal);
