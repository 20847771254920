/* eslint-disable function-paren-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withSidebar from '../withSidebar';
import NumberCard from '../components/NumberCard';
import Table from '../components/Tables/ArtistsTable';
import Loader from '../components/Loader';
import Error from '../components/Error';
import fetchArtistsAction from '../redux/actions/artists/fetchArtists';

const Artists = ({
  fetchArtistsAction: fetchArtists,
  getArtists,
  profile: { monthlyStreams, totalStreams, totalRevenue },
}) => {
  const history = useHistory();
  const [artistsData, setArtistsData] = useState([]);
  const [status, setStatus] = useState('initial');
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_PROVIDER_TOKEN')) {
      return history.push('/login');
    }
    if (status === 'initial') {
      fetchArtists();
      setStatus('fetching');
    }

    if (getArtists.status === 'clear') {
      setStatus('fetching');
      setArtistsData({});
    }

    if (getArtists.status === 'fetch_artists_success') {
      setStatus('success');
      setArtistsData(getArtists.results);
    }

    if (getArtists.status === 'fetch_artists_error') {
      const { error } = getArtists;
      if (error.status === 500) setStatus('unknown_error');
      if (error.status === 404) setStatus('no_data');
    }

    return undefined;
  }, [getArtists]);

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'no_data':
        data = (
          <Error title="No data found!" description="No artist added yet!" />
        );
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  const artists =
    artistsData && searchValue
      ? artistsData.filter((artist) =>
          artist.name.toUpperCase().includes(searchValue.toUpperCase())
        )
      : artistsData;

  return (
    <>
      <div className="row mt-2">
        <NumberCard col={4} count={monthlyStreams} body="Streams This Month" />
        <NumberCard col={4} count={totalStreams} body="Total Streams" />
        <NumberCard col={4} count={totalRevenue} body="Total Revenue" />
      </div>
      <div className="py-3">
        <div className="row py-2">
          <div className="col-4">
            <form>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control searchInput"
                  placeholder="Search for an artist..."
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            </form>
          </div>
          <div className="col-8 text-right">
            <Link to="/artists/new" className="btn btn-primary py-1 px-4">
              + Add Artist
            </Link>
          </div>
        </div>
        <DisplayData>
          {artists.length ? (
            <Table data={artists} />
          ) : (
            <Error
              title="No results found!"
              description="Your search didn't match anything, have another go."
            />
          )}
        </DisplayData>
      </div>
    </>
  );
};

Artists.propTypes = {
  fetchArtistsAction: PropTypes.func.isRequired,
  getArtists: PropTypes.objectOf(PropTypes.any).isRequired,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ getArtists }) => ({ getArtists });

export default connect(mapStateToProps, { fetchArtistsAction })(
  withSidebar(Artists, 'music')
);
