/* eslint-disable operator-linebreak */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Error from '../../components/Error';
import Loader from '../../components/Loader';
import withSidebar from '../../withSidebar';
import SignUpNav from '../../components/forms/SignUpNav';
import PersonalInfo from '../../components/forms/PersonalInfo';
import Identification from '../../components/forms/Identification';
import ArtistInfo from '../../components/forms/ArtistInfo';
import updateArtistAction from '../../redux/actions/artists/updateArtist';
import singleArtistAction from '../../redux/actions/artists/singleArtist';

const AddArtist = ({
  updateArtistAction: updateAction,
  singleArtistAction: fetchAction,
  singleArtist,
  updateArtist,
}) => {
  const { id } = useParams();
  const [status, setStatus] = useState('initial');
  const [profileData, setProfileData] = useState({});
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData1, setFormData1] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phoneNumber: '',
  });
  const [formData2, setFormData2] = useState({
    img: null,
    nationalIdCard: '',
  });
  const [formData3, setFormData3] = useState({
    img: null,
    file: null,
    name: '',
    profilePhotoLink: '',
    checked: false,
  });
  const history = useHistory();

  useEffect(() => {
    setError('');
    setLoading(false);
    if (updateArtist.status === 'success') {
      setLoading(false);
      setError('');
      return history.push(`/artists/${id}/application/update/success`);
    }
    if (updateArtist.status === 'error') {
      setLoading(false);
      return setError(`Error: ${updateArtist.error.message}`);
    }

    if (status === 'initial') {
      fetchAction(id);
      setStatus('fetching');
    }

    if (singleArtist.status === 'clear') {
      setStatus('fetching');
      setProfileData({});
    }

    if (singleArtist.status === 'success') {
      setStatus('success');
      setProfileData(singleArtist.results.artist);
    }

    if (singleArtist.status === 'error') {
      const { error: dispError } = singleArtist;
      if (dispError.status === 500) return setStatus('unknown_error');
      if (dispError.status === 404) return setStatus('no_data');
    }

    return undefined;
  }, [updateArtist, singleArtist]);

  const handleNext = (data) => {
    if (step === 1) {
      setFormData1(data);
      setStep(step + 1);
    }
    if (step === 2) {
      setFormData2(data);
      setStep(step + 1);
    }
    if (step === 3) {
      setFormData3(data);
      let payload;
      const check =
        formData1.firstname !== profileData.firstname ||
        formData1.lastname !== profileData.lastname ||
        formData1.phoneNumber !==
          profileData.phoneNumber.slice(4, profileData.phoneNumber.length) ||
        formData2.nationalIdCard !== profileData.nationalIdCard ||
        data.name !== profileData.name ||
        data.profilePhotoLink !== profileData.profilePhotoLink;

      if (check) {
        payload = new FormData();
        payload.append('firstname', formData1.firstname);
        payload.append('lastname', formData1.lastname);
        payload.append('email', formData1.email);
        payload.append('phoneNumber', `+250${formData1.phoneNumber}`);
        payload.append('nationalIdCard', formData2.nationalIdCard);
        payload.append('name', data.name);
        payload.append('profilePhotoLink', data.profilePhotoLink);
        payload.append('contract', profileData.contract);
      }

      if (data.file) {
        payload = new FormData();
        payload.append('contract', data.file);
      }

      if (payload) {
        updateAction(payload, id);
      } else {
        return setError('Error: Please change any thing, to update!');
      }
      setLoading(true);
      setError('');
    }
    return undefined;
  };

  const handlePrev = (data) => {
    if (step === 2 && data !== null) {
      setFormData2(data);
      setStep(step - 1);
    }
    if (step === 3 && data !== null) {
      setFormData3(data);
      setStep(step - 1);
    } else {
      setStep(step - 1);
    }
  };

  const currentComponent = (val) => {
    switch (val) {
      case 1:
        return (
          <PersonalInfo
            onNext={handleNext}
            onPrev={handlePrev}
            step={step}
            formData={formData1}
            profileData={profileData}
            type="update"
          />
        );
      case 2:
        return (
          <Identification
            onNext={handleNext}
            onPrev={handlePrev}
            step={step}
            formData={formData2}
            profileData={profileData}
            type="update"
          />
        );
      case 3:
        return (
          <ArtistInfo
            onNext={handleNext}
            onPrev={handlePrev}
            step={step}
            formData={formData3}
            profileData={profileData}
            type="update"
            loading={loading}
          />
        );
      default:
        return <></>;
    }
  };

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'no_data':
        data = (
          <Error
            title="No data found!"
            description="The artist does not exist!"
          />
        );
        break;
      case 'unknown_error':
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
        break;
      default:
        data = <Loader />;
    }
    return data;
  };

  return (
    <>
      <h5
        className="text-white text-center mt-4"
        style={{ fontSize: 18, width: '90%', margin: 'auto' }}
      >
        Add New Artist
      </h5>
      <SignUpNav step={step} status="in-progress" />
      <DisplayData>{currentComponent(step)}</DisplayData>
      <p className="text-center text-danger" style={{ fontSize: 15 }}>
        {error}
      </p>
    </>
  );
};

AddArtist.propTypes = {
  updateArtist: PropTypes.objectOf(PropTypes.any).isRequired,
  updateArtistAction: PropTypes.func.isRequired,
  singleArtist: PropTypes.objectOf(PropTypes.any).isRequired,
  singleArtistAction: PropTypes.func.isRequired,
};

const mapStateToProps = ({ updateArtist, singleArtist }) => ({
  updateArtist,
  singleArtist,
});

export default connect(mapStateToProps, {
  updateArtistAction,
  singleArtistAction,
})(withSidebar(AddArtist, 'music'));
