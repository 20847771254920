/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import withSidebar from '../../withSidebar';
import SignUpNav from '../../components/forms/SignUpNav';
import PersonalInfo from '../../components/forms/PersonalInfo';
import Identification from '../../components/forms/Identification';
import ArtistInfo from '../../components/forms/ArtistInfo';
import signupAction from '../../redux/actions/artists/addArtist';

const AddArtist = ({ signupAction: signAction, addArtist }) => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData1, setFormData1] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phoneNumber: '',
  });
  const [formData2, setFormData2] = useState({
    img: null,
    nationalIdCard: '',
  });
  const [formData3, setFormData3] = useState({
    img: null,
    file: null,
    name: '',
    profilePhotoLink: '',
    checked: false,
  });
  const history = useHistory();

  useEffect(() => {
    setError('');
    setLoading(false);
    if (addArtist.status === 'success') {
      setLoading(false);
      setError('');
      return history.push('/artists/new/success');
    }
    if (addArtist.status === 'error') {
      setLoading(false);
      return setError(`Error: ${addArtist.error.message}`);
    }

    return undefined;
  }, [addArtist]);

  const handleNext = (data) => {
    if (step === 1) {
      setFormData1(data);
      setStep(step + 1);
    }
    if (step === 2) {
      setFormData2(data);
      setStep(step + 1);
    }
    if (step === 3) {
      setFormData3(data);
      const payload = new FormData();
      payload.append('firstname', formData1.firstname);
      payload.append('lastname', formData1.lastname);
      payload.append('email', formData1.email);
      payload.append('phoneNumber', `+250${formData1.phoneNumber}`);
      payload.append('nationalIdCard', formData2.nationalIdCard);
      payload.append('name', data.name);
      payload.append('profilePhotoLink', data.profilePhotoLink);
      payload.append('contract', data.file);

      setLoading(true);
      setError('');
      signAction(payload);
    }
  };

  const handlePrev = (data) => {
    if (step === 2 && data !== null) {
      setFormData2(data);
      setStep(step - 1);
    }
    if (step === 3 && data !== null) {
      setFormData3(data);
      setStep(step - 1);
    } else {
      setStep(step - 1);
    }
  };

  const currentComponent = (val) => {
    switch (val) {
      case 1:
        return (
          <PersonalInfo
            onNext={handleNext}
            onPrev={handlePrev}
            step={step}
            formData={formData1}
          />
        );
      case 2:
        return (
          <Identification
            onNext={handleNext}
            onPrev={handlePrev}
            step={step}
            formData={formData2}
          />
        );
      case 3:
        return (
          <ArtistInfo
            onNext={handleNext}
            onPrev={handlePrev}
            step={step}
            formData={formData3}
            loading={loading}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <h5
        className="text-white text-center mt-4"
        style={{ fontSize: 18, width: '90%', margin: 'auto' }}
      >
        Add New Artist
      </h5>
      <SignUpNav step={step} status="in-progress" />
      {currentComponent(step)}
      <p className="text-center text-danger" style={{ fontSize: 15 }}>
        {error}
      </p>
    </>
  );
};

AddArtist.propTypes = {
  addArtist: PropTypes.objectOf(PropTypes.any).isRequired,
  signupAction: PropTypes.func.isRequired,
};

const mapStateToProps = ({ addArtist }) => ({
  addArtist,
});

export default connect(mapStateToProps, {
  signupAction,
})(withSidebar(AddArtist, 'music'));
